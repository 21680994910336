<template>
  <div>
    <v-container>
      <v-row dense>
        <v-container>
          <h2>
            {{ frmTxt }}
          </h2>
          <v-divider color="primary" class="my-2"></v-divider>
        </v-container>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="10">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                v-if="isEdit"
                v-model="rr.RRId"
                label="เอกสาร"
                readonly
                dense
                outlined
                ref="RRId"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-autocomplete
                v-model="rr.WarehouseId"
                :items="warehouseList"
                item-value="WarehouseId"
                item-text="WarehouseName"
                :return-object="false"
                dense
                outlined
                label="คลังสินค้า"
                :readonly="isEdit"
                ref="WarehouseId"
                @change="NextFocus('RRDateStr')"
              >
              </v-autocomplete
            ></v-col>
            <v-col cols="12" sm="3">
              <v-menu
                v-model="menuRRDate"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                :open-on-click="!isEdit"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="rr.RRDateStr"
                    label="วันที่"
                    prepend-icon="mdi-calendar"
                    :readonly="isEdit"
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    ref="RRDateStr"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="rr.RRDateStr"
                  locale="th-TH"
                  @input="menuRRDate = false"
                  @change="NextFocus('SupplierId')"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="3">
              <v-autocomplete
                v-model="rr.SupplierId"
                :items="supplierList"
                item-value="SupplierId"
                item-text="SupplierName"
                :return-object="false"
                dense
                outlined
                label="ผู้จำหน่าย"
                ref="SupplierId"
                @change="supplierIdChange(rr.SupplierId)"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3"
              ><v-autocomplete
                v-model="rr.VatType"
                :items="enumVatType"
                item-value="id"
                item-text="name"
                :return-object="false"
                dense
                outlined
                label="ประเภทภาษี"
                ref="VatType"
                @change="vatTypeChange"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="rr.InvoiceNo"
                label="ใบแจ้งหนี้"
                dense
                outlined
                ref="InvoiceNo"
                @keydown.enter="NextFocus('InvoiceDateStr')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-menu
                v-model="menuInvoiceDate"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="rr.InvoiceDateStr"
                    label="วันที่ใบแจ้งหนี้"
                    prepend-icon="mdi-calendar"
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    ref="InvoiceDateStr"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="rr.InvoiceDateStr"
                  locale="th-TH"
                  @input="menuInvoiceDate = false"
                  @change="NextFocus('DiscountPercent')"
                ></v-date-picker> </v-menu
            ></v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="rr.Remark"
                label="หมายเหตุ"
                dense
                outlined
                ref="Remark"
              ></v-text-field>
            </v-col>
            <v-row dense>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model.number="rr.DiscountPercent"
                  label="ส่วนลด(%)"
                  type="number"
                  dense
                  outlined
                  required
                  ref="DiscountPercent"
                  @keydown.enter="NextFocus('DiscountAmount')"
                  @change="calTotal"
                  @focus="SelectAll('DiscountPercent')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model.number="rr.DiscountAmount"
                  label="ส่วนลด(บาท)"
                  type="number"
                  dense
                  outlined
                  required
                  ref="DiscountAmount"
                  @keydown.enter="NextFocus('Vat')"
                  @change="calTotal"
                  @focus="SelectAll('DiscountAmount')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model.number="rr.DiscountTotal"
                  label="ส่วนลดรวม"
                  type="number"
                  dense
                  outlined
                  required
                  filled
                  readonly
                  ref="DiscountTotal"
                  @keydown.enter="NextFocus('Vat')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model.number="rr.Net"
                  label="ยอดก่อนภาษี"
                  type="number"
                  dense
                  outlined
                  required
                  filled
                  readonly
                  ref="Net"
                  @keydown.enter="NextFocus('Vat')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model.number="rr.Vat"
                  label="ภาษี"
                  type="number"
                  dense
                  outlined
                  required
                  ref="Vat"
                  @change="editVat"
                  @focus="SelectAll('Vat')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                  v-model.number="rr.Total"
                  label="ยอดรวม"
                  type="number"
                  dense
                  outlined
                  required
                  filled
                  readonly
                  ref="Total"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-col cols="12" sm="3">
          <v-text-field
            v-model.number="rr.DiscountTotal"
            label="ส่วนลดรวม"
            type="number"
            dense
            required
            readonly
            ref="DiscountTotal"
            @keydown.enter="NextFocus('WarehouseId')"
          ></v-text-field>
        </v-col> -->
          </v-row>
        </v-col>
        <v-col cols="12" sm="2"
          ><v-row class="py-1 px-2" dense>
            <v-btn color="primary" block @click="save"
              ><v-icon left dense>mdi-content-save</v-icon>บันทึก</v-btn
            >
          </v-row>
          <v-row class="py-1 px-2" dense v-if="!isEdit">
            <v-btn color="secondary" class="white--text" block @click="clear"
              >เคลียร์</v-btn
            >
          </v-row>
        </v-col>
      </v-row>

      <v-divider color="primary" class="my-2"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="dataTable"
            :items-per-page="10"
            class="elevation-1"
            dense
          >
            <template v-slot:top>
              <v-row class="mx-2" no-gutters justify="end">
                <v-btn color="primary" dark class="my-3" @click="newLine">
                  <v-icon left dense>mdi-plus</v-icon>สร้างใหม่
                </v-btn>
              </v-row>
            </template>
            <template v-slot:item.Price="{ item }">
              <span>{{ FormatNumber(item.Price) }}</span>
            </template>
            <template v-slot:item.Qty="{ item }">
              <span>{{ FormatNumber(item.Qty, 0) }}</span>
            </template>
            <template v-slot:item.DiscountTotal="{ item }">
              <span>{{ FormatNumber(item.DiscountTotal) }}</span>
            </template>
            <template v-slot:item.Total="{ item }">
              <span>{{ FormatNumber(item.Total) }}</span>
            </template>
            <template v-slot:item.Actions="{ item }">
              <v-icon class="mr-3 primary--text" @click="editLine(item)">
                mdi-pencil
              </v-icon>
              <v-icon class="mr-3 red--text" @click="deleteLine(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:item.Vat="{ item }">
              <v-icon class="info--text" v-if="item.Vat == 1">
                mdi-check-bold
              </v-icon>
              <v-icon class="secondary--text" v-if="item.Vat == 0">
                mdi-close-thick
              </v-icon>
            </template>
            <template v-slot:item.Status="{ item }">
              <v-icon class="info--text" v-if="item.Status == 1">
                mdi-check-bold
              </v-icon>
              <v-icon class="secondary--text" v-if="item.Status == 0">
                mdi-close-thick
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <dialogEdit
      :modal="showDialogEdit"
      :item="itemDialogEdit"
      :itemIndex="itemIndexDialogEdit"
      @close="closeDialogEdit"
    >
    </dialogEdit>
  </div>
</template>

<script>
import router from "@/router";
import dialogEdit from "./DialogEditReceiveRecord";
import baseEnum from "@js/enum";
import {
  newReceiveRecord,
  newReceiveRecordLine,
} from "@/store/modules/transactions/state";
import { mapActions, mapState } from "vuex";
// import LoginVue from '../../../Login.vue';
export default {
  components: {
    dialogEdit,
  },
  props: ["item"],
  data() {
    return {
      frmTxt: "",
      isEdit: false,
      menuRRDate: false,
      menuInvoiceDate: false,
      rr: newReceiveRecord(),
      supplierList: [],
      warehouseList: [],
      enumStatus: baseEnum.status.data,
      enumVatType: baseEnum.vatType.data,
      lines: [],
      showDialogEdit: false,
      itemDialogEdit: {},
      itemIndexDialogEdit: -1,
      headers: [
        {
          text: "สินค้า",
          value: "ProductName",
          divider: true,
        },
        {
          text: "จำนวน",
          value: "Qty",
          divider: true,
          align: "end",
          width: 100,
        },
        {
          text: "หน่วย",
          value: "UnitName",
          divider: true,
          width: 100,
        },
        {
          text: "ราคา",
          value: "Price",
          divider: true,
          align: "end",
          width: 100,
        },
        {
          text: "ส่วนลด",
          value: "DiscountTotal",
          divider: true,
          align: "end",
          width: 100,
        },
        {
          text: "ราคารวม",
          value: "Total",
          divider: true,
          align: "end",
          width: 100,
        },
        {
          text: "หมายเหตุ",
          value: "Remark",
          divider: true,
          width: 110,
        },
        {
          text: "วันที่ผลิต",
          value: "MFGDateStr",
          divider: true,
          width: 110,
        },
        {
          text: "หมดอายุ",
          value: "EXPDateStr",
          divider: true,
          width: 110,
        },
        {
          text: "ภาษี",
          value: "Vat",
          divider: true,
          width: 80,
        },
        {
          text: "",
          value: "Actions",
          sortable: false,
          align: "center",
        },
      ],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState("auth", ["user"]),
    dataTable: function() {
        return this.lines.filter((list) => list.Status == 1);
    },
  },
  methods: {
    ...mapActions("settings", [
      "actSupplierGets",
      "actWarehouseGets",
      "actSupplierGet",
    ]),
    ...mapActions("transactions", [
      "actReceiveRecordCreate",
      "actReceiveRecordUpdate",
      "actReceiveRecordGet",
    ]),
    init() {
      this.getSupplierList();
      this.getWareouseList();
      if (this.item == undefined) {
        this.frmTxt = "สร้างใบรับสินค้า";
        this.isEdit = false;
        this.rr = Object.assign({}, newReceiveRecord());
      } else {
        this.frmTxt = "แก้ไขใบรับสินค้า";
        this.isEdit = true;
        this.getRR(this.item.RRId);
      }
    },
    save() {
      // console.log(this.rr);
      if (this.rr.WarehouseId == "") {
        this.Warning("กรุณาเลือกคลังสินค้า");
        return false;
      }
      if (this.rr.RRDateStr == "") {
        this.Warning("กรุณาระบุวันที่");
        return false;
      }
      if (this.rr.SupplierId == "") {
        this.Warning("กรุณาเลือกผู้จำหน่าย");
        return false;
      }
      // if (this.rr.VatType == "") {
      //   this.Warning("กรุณาเลือกประเภทภาษี");
      //   return false;
      // }
      if (this.rr.InvoiceNo == "") {
        this.Warning("กรุณาระบุใบแจ้งหนี้");
        return false;
      }
      if (this.rr.InvoiceDateStr == "") {
        this.Warning("กรุณาระบุวันที่ใบแจ้งหนี้");
        return false;
      }
      if (this.lines.length == 0) {
        this.Warning("กรุณาเพิ่มรายการสินค้า");
        return false;
      }
      this.rr.Lines = this.lines;
      console.log(this.rr);
      if (this.isEdit) {
        this.rr.CreatedBy = this.user.UserId;
        this.rr.ModifiedBy = this.user.UserId;
        this.actReceiveRecordUpdate(this.rr).then(({ status, data }) => {
          if (status == 200 && data.success) {
            // this.closeModal();
            router.push({ name: "ReceiveRecord" });
          } else {
            this.Error(data.message);
          }
        });
      }
      else {
        this.rr.CreatedBy = this.user.UserId;
        this.rr.ModifiedBy = this.user.UserId;
        this.actReceiveRecordCreate(this.rr).then(({ status, data }) => {
          if (status == 200 && data.success) {
            // this.closeModal();
            router.push({ name: "ReceiveRecord" });
          } else {
            this.Error(data.message);
          }
        });
      }

    },
    clear() {
      this.rr = Object.assign({}, newReceiveRecord());
      this.lines = [];
    },
    vatTypeChange() {
      this.calTotal();
      this.$refs.["InvoiceNo"].focus();
    },
    supplierIdChange(supplierId) {
      let parm = { SupplierId: supplierId };
      this.actSupplierGet(parm).then(({ status, data }) => {
        if (status == 200 && data.success) {
          this.rr.VatType = data.val[0].VatType;
          this.$refs.["InvoiceNo"].focus();
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    getRR(RRId) {
      let parm = { RRId: RRId };
      this.actReceiveRecordGet(parm).then(({ status, data }) => {
        if (status == 200 && data.success) {
          this.rr = Object.assign({}, this.rr, data.val[0]);
          this.lines = data.val[0].Lines;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    newLine() {
      this.itemDialogEdit = Object.assign({}, newReceiveRecordLine());
      this.itemIndexDialogEdit = -1;
      this.showDialogEdit = true;
    },
    editLine(item) {
      item.Product = {ProductId : item.ProductId, ProductName: item.ProductName};
      item.Unit = {UnitId : item.UnitId, UnitName: item.UnitName};
      this.itemDialogEdit = Object.assign({}, this.itemDialogEdit, item);
      this.itemIndexDialogEdit = this.lines.indexOf(item);
      this.showDialogEdit = true;
    },
    deleteLine(item) {
      this.Confirm("ยืนยันการลบรายการ ?").then((e) => {
        if (e) {
          //this.lines.splice(this.lines.indexOf(item), 1);
          this.lines[this.lines.indexOf(item)].Status = 0;
        }
      });
    },
    getSupplierList() {
      this.actSupplierGets().then(({ status, data }) => {
        if (status == 200 && data.success) {
          this.supplierList = data.val;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    getWareouseList() {
      this.actWarehouseGets().then(({ status, data }) => {
        if (status == 200 && data.success) {
          this.warehouseList = data.val;
        } else {
          this.Error("เกิดข้อผิดพลาด : " + data.message);
        }
      });
    },
    editVat() {
      console.log(1);
      let total = this.rr.Total;
      let net = this.rr.Net;
      let vat = this.rr.Vat;
      if (this.rr.VatType == 0) {//noVat
        this.rr.Vat = 0;
      }
      else if (this.rr.VatType == 1) { //vatIn
        console.log(2);
        console.log(vat);
        console.log(total);
        this.rr.Vat = vat;
        this.rr.Net =  total - vat;
        this.rr.Total = total;
      }
      else if (this.rr.VatType == 2) { //vatOut
        this.rr.Vat = vat;
        this.rr.Net = net;
        this.rr.Total = net + vat;
      }
    },
    calTotal() {
      let sumLines = 0;
      let amount = 0;
      this.lines.forEach((e) => {
        if (e.Status == 1) {
          sumLines = sumLines + e.Total;
        }
      });
      this.rr.DiscountTotal = (sumLines * this.rr.DiscountPercent / 100) + this.rr.DiscountAmount;
      amount = sumLines - this.rr.DiscountTotal;
      if (this.rr.VatType == 0) {//noVat
        this.rr.Vat = 0;
        this.rr.Net = amount;
        this.rr.Total = amount;
      }
      else if (this.rr.VatType == 1) { //vatIn
        this.rr.Vat = (amount - (amount * 100 / 107)).toFixed(2);
        this.rr.Net =  (amount * 100 / 107).toFixed(2);
        this.rr.Total = amount;
      }
      else if (this.rr.VatType == 2) { //vatOut
        this.rr.Vat = (amount * 0.07).toFixed(2);
        this.rr.Net = amount;
        this.rr.Total = (amount + (amount * 0.07)).toFixed(2);
      }
    },
    closeDialogEdit(value) {
      this.showDialogEdit = value.modal;
      if (value.index == -1) {
        //เพิ่ม
        // console.log(value.item);
        this.lines.push(value.item);
      } else if (value.index >= 0) {
        //แก้ไข
        Object.assign(this.lines[value.index], value.item);
      }
    },
  },
  watch: {
    lines: {
      deep: true,
      handler() {
        this.calTotal();
      },
    },
    "rr.RRDateStr": function(val) {
      this.rr.RRDate = val;
    },
    "rr.InvoiceDateStr": function(val) {
      this.rr.InvoiceDate = val;
    },
  },
};
</script>

<style></style>
